export const bookingConfig = {
	weekDailyHours: 8,
	weekendDailyHours: 6,
	capacities: [
		{ label: '1-4', value: '1-4' },
		{ label: '4-8', value: '4-8' },
		{ label: '8-12', value: '8-12' },
		{ label: '12-16', value: '12-16' },
		{ label: '16+', value: '16-' },
	],
	hours: { min: 8, max: 23 },
	months: 12,
	earlyRebate: {
		code: 'promotion.early_booking',
		percent: 10,
		days: 30,
	},
	warningTimeout: 5,
	recurring: {
		typeformRecurringBookingURL: {
			sidebar: 'https://cocoonspace.typeform.com/to/mjSNTPuw',
			pricingSection: 'https://cocoonspace.typeform.com/to/updKKFB7',
		},
		config: {
			minDays: 1,
			maxDays: 2,
		},
		offers: [
			{
				daysPerWeek: 1,
				rebate: 0.6,
				billedHours: 8,
			},
			{
				daysPerWeek: 2,
				rebate: 0.65,
				billedHours: 8,
			},
		],
	},
} as const

export const BOOKING_CONFIG = bookingConfig
