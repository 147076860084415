import { APP_CONFIG } from '@cocoonspace/shared/config'
import {
	HydrationBoundary,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import PlausibleProvider from 'next-plausible'
import { NuqsAdapter } from 'nuqs/adapters/next/pages'
import type { PropsWithChildren } from 'react'
import { analytics } from '~/lib/analytics'
import { TrackingProvider } from '~/providers/tracking-provider'
import { UserProvider } from '~/providers/user-provider'
import { TooltipProvider } from './ui/tooltip'

const queryClient = new QueryClient()

export const Providers = ({
	children,
	pageProps,
}: PropsWithChildren<{ pageProps: any }>) => (
	<PlausibleProvider
		enabled
		// trackLocalhost
		domain={
			APP_CONFIG.appEnv === 'preprod'
				? 'preprod.cocoon-space.dev'
				: 'cocoon-space.com'
		}
	>
		<NuqsAdapter>
			<QueryClientProvider client={queryClient}>
				<HydrationBoundary state={pageProps.dehydratedState}>
					<TrackingProvider instance={analytics}>
						<UserProvider>
							<TooltipProvider delayDuration={300}>{children}</TooltipProvider>
						</UserProvider>
					</TrackingProvider>
				</HydrationBoundary>

				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</NuqsAdapter>
	</PlausibleProvider>
)
