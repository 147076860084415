import { AnimatePresence } from 'framer-motion'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { cn } from '~/lib/utils'
import { CocoonOrDeskBtn } from './cocoon-or-desk-btn'
import { FindACocoonBtn } from './find-a-cocoon-btn'
import { NavbarSkeleton } from './nav-bar-menu-skeleton'
import { OfferSwitch } from './offer-switch'

const NavbarMenu = dynamic(
	() => import('./nav-bar-menu').then((res) => res.NavbarMenu),
	{ ssr: false, loading: () => <NavbarSkeleton /> },
)

const BrandLink = () => (
	<Link
		href='/'
		className='mr-auto flex flex-row items-start gap-2 pb-1 font-bold text-primary text-xl leading-none md:text-4xl'
	>
		cocoon space
		{process.env.NEXT_PUBLIC_APP_ENV !== 'prod' && (
			<span className='pt-2 text-[10px] text-slate-400 uppercase leading-none'>
				{process.env.NEXT_PUBLIC_APP_ENV}
			</span>
		)}
	</Link>
)

const NavBarContent = () => {
	const router = useRouter()

	const isDashboard = router.route === '/dashboard'
	const isSearchPages = ['/[zone]', '/desks/[zone]'].includes(router.route)

	if (isDashboard) return <CocoonOrDeskBtn />
	if (isSearchPages) return <OfferSwitch />
	return <FindACocoonBtn />
}

export const AppNavbar = () => (
	<nav
		className={cn(
			'sticky inset-x-0 top-0 z-40 h-[60px] w-full print:hidden',
			'bg-white shadow',
			'flex shrink-0 flex-row items-center px-6',
			'transition-all delay-100',
		)}
	>
		<BrandLink />

		<div className='-translate-x-1/2 absolute left-1/2 inline-flex max-md:hidden'>
			<AnimatePresence>
				<NavBarContent />
			</AnimatePresence>
		</div>

		<NavbarMenu />
	</nav>
)
