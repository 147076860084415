import { motion, useScroll } from 'framer-motion'
import { Search } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { buttonVariants } from '~/components/ui/button'
import { useUserContext } from '~/providers/user-provider'

const ROUTES_WITHOUT_SEARCH = [
	'/booking',
	'/booking/[bookingId]/extras',
	'/booking/[bookingId]/payment',
	'/event/[eventId]/extras',
	'/event/extras/[bookingId]',
	'/[zone]',
	'/desks/[zone]',
	'/salle-de-reunion/[zone]',
	'/salle-de-reunion/[zone]/[district]',
	'/bureau-a-l-heure/[zone]',
	'/onboarding',
	'/onboarding/email',
	'/onboarding/personal-infos',
	'/onboarding/password',
	'/onboarding/email-validation',
]

export const FindACocoonBtn = () => {
	const router = useRouter()
	const { t } = useTranslation()
	const { userQuery } = useUserContext()

	const [isSearchVisible, setIsSearchVisible] = useState(false)
	const { scrollY } = useScroll()

	useEffect(() => {
		if (router.route === '/') {
			setIsSearchVisible(false)

			const unsub = scrollY.on('change', (y) => setIsSearchVisible(y > 450))

			return () => unsub()
		} else {
			setIsSearchVisible(true)
		}
	}, [router.route])

	if (ROUTES_WITHOUT_SEARCH.includes(router.route)) return null
	if (!isSearchVisible) return null

	return (
		<motion.div
			exit={{ opacity: 0, scale: 1.1 }}
			initial={{ opacity: 0, scale: 0.5 }}
			animate={{ opacity: 1, scale: 1 }}
		>
			<Link
				data-testid='find-cocoon-button'
				className={buttonVariants({ variant: 'gradient' })}
				href={`/${userQuery.data?.default_zone || 'paris'}`}
			>
				<Search className='mr-2 size-4' />

				{t('cwNavbar:findCocoon')}
			</Link>
		</motion.div>
	)
}
