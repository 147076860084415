import { searchConfig } from '@cocoonspace/shared/config/search-config'
import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { useMediaQuery } from '@cocoonspace/shared/hooks/use-media-query.hook'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { type PropsWithChildren, useMemo } from 'react'
import { cn } from '~/lib/utils'
import { useUserContext } from '~/providers/user-provider'
import { twTheme } from '~/theme'
import { Card } from '../ui/card'

const Item = ({
	href,
	isActive,
	children,
}: PropsWithChildren<{ href: string; isActive: boolean }>) => (
	<Link
		href={href}
		className={cn(
			'flex w-[100px] flex-1 items-center justify-center gap-2 rounded border capitalize',
			'first:rounded-r-none first:border-r-0 last:rounded-l-none last:border-l-0',
			{
				'border-primary bg-primary font-bold text-primary-foreground': isActive,
			},
		)}
	>
		{children}
	</Link>
)

export const OfferSwitch = () => {
	const router = useRouter()
	const isDesktop = useMediaQuery(`(min-width: ${twTheme.theme.screens.md})`)
	const { userQuery } = useUserContext()

	const { isAuth } = useAuth()

	const getCurrentZone = () => {
		if (isAuth && userQuery.data?.default_zone) {
			return userQuery.data.default_zone
		}

		if (
			typeof router.query.zone === 'string' &&
			Object.keys(searchConfig.zones).includes(router.query.zone)
		) {
			return router.query.zone
		}

		return 'paris'
	}

	const currentZone = getCurrentZone()

	const linkQueryParams = useMemo(() => {
		if (isDesktop && router.query.isMapVisible === undefined) {
			return ''
		}

		return `?isMapVisible=${router.query.isMapVisible}`
	}, [router.query.isMapVisible, isDesktop])

	return (
		<Card
			data-testid='zone-switch'
			className='flex h-[40px] flex-row'
		>
			<Item
				href={`/${currentZone}${linkQueryParams}`}
				isActive={router.route === '/[zone]'}
			>
				Cocoons
			</Item>

			<Item
				href={`/desks/${currentZone}${linkQueryParams}`}
				isActive={router.route === '/desks/[zone]'}
			>
				Desks
			</Item>
		</Card>
	)
}
