import { Trans } from 'next-i18next'
import Link from 'next/link'
import { buttonVariants } from '~/components/ui/button'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '~/components/ui/tooltip'
import { useUserContext } from '~/providers/user-provider'

export const CocoonOrDeskBtn = () => {
	const { userQuery } = useUserContext()

	return (
		<TooltipProvider>
			<div className='!px-6 [&_a]:!border-white flex flex-row items-center divide-x divide-transparent'>
				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<Link
							href={`/${userQuery.data?.default_zone || 'paris'}`}
							data-testid='find-cocoon-button'
							className={buttonVariants({
								className:
									'flex w-[150px] flex-col rounded-r-none leading-none',
							})}
						>
							<span className='font-normal text-xs'>{'Trouver'}</span>

							{'Un Cocoon'}
						</Link>
					</TooltipTrigger>

					<TooltipContent
						className='text-center'
						sideOffset={15}
					>
						<Trans defaults='Un espace <strong>privé et cosy</strong> pour vos réunions <br/> et rendez-vous professionnels.' />
					</TooltipContent>
				</Tooltip>

				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<Link
							href='/desks/paris'
							className={buttonVariants({
								className:
									'flex w-[150px] flex-col rounded-l-none leading-none',
							})}
						>
							<span className='font-normal text-xs'>{'Découvrir'}</span>
							Le Desk
						</Link>
					</TooltipTrigger>

					<TooltipContent
						className='divide-none text-center'
						sideOffset={15}
					>
						<Trans defaults='Un espace de travail <strong>partagé</strong> <br/> à la demande.' />
					</TooltipContent>
				</Tooltip>
			</div>
		</TooltipProvider>
	)
}
